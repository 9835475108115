.pricing-page {
  height: 100%;
  padding: 4rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.pricing-page h1 {
  color: white;
}

.pricing-page h2 {
  color: white;
}

.pricing-card {
  position: relative;
  width: 300px;
  background-color: whitesmoke;
  color: var(--secondaryColor);
  margin: 1rem;
  padding: 1rem;
  border-radius: var(--globalBorderRadius);
  box-shadow: var(--boxShadow);
}

@media (min-width: 300px) {
  .pricing-card {
    margin: 1rem 0;
    width: 100%;
  }
}

@media (min-width: 700px) {
  .pricing-card {
    margin: 1rem;
    width: 300px;
  }
}

@media (min-width: 1000px) {
  .pricing-card {
    margin: 1rem;
  }
}

.pricing-card.free {
  /* width: 300px; */
}

.pricing-card h2 {
  color: var(--secondaryColor);
  text-align: center;
}

.pricing-card-wrapper {
  margin: 1rem auto;
  display: flex;
  flex-wrap: wrap;
}

.pricing-card-price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.pricing-card-price {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pricing-card-price > .primary-button {
  width: 100%;
  margin: 0 1rem;
}

.pricing-card-bottom {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0;
}

.pricing-card-content {
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  font-weight: 500;
  text-align: center;
}

.pricing-card-content ul {
  list-style: none;
  text-indent: -1.5rem;
}

.pricing-card-content li::before {
  content: '✔️ ';
  color: var(--secondaryColor);
}

.pricing-card-price-details {
  margin-bottom: 1rem;
  text-align: center;
}

.pricing-card-price-details h2 {
  text-align: center;
  margin: 0;
}

.pricing-card-price-details p {
  text-align: center;
  color: var(--secondaryColor);
  margin: 0;
}

.pricing-card-price-details small {
  text-align: center;
  margin: 0;
}
