.account-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.account-section {
  margin: 2rem auto;
  padding: 1rem;
}
