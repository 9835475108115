.login-wrapper {
  display: flex;
  padding: 4rem 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: var(--backgroundColor);
}

.login-form > form > input {
  height: 25px;
  padding: 0.5rem;
  margin: 1rem 0;
  border-style: solid;
  border-radius: var(--globalBorderRadius);
  border-color: '#fffff1';
}

.login-form > form {
  display: flex;
  border-radius: var(--globalBorderRadius);
  flex-direction: column;
  background-color: white;
  padding: 2rem;
  width: 300px;
}

.login-form > form > h1 {
  text-align: center;
}

@media (min-width: 300px) {
  .features {
    width: 100%;
  }

  .payment-section {
    width: 100%;
  }
}

@media (min-width: 700px) {
  .payment-section {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .payment-section {
    width: 500px;
  }
}

.signin-google {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: 'white';
  color: 'black';
  border-style: 'solid';
  height: 45px;
  width: 100%;
  margin-bottom: 1rem;
  background-color: white;
  border-style: solid;
  border-radius: var(--globalBorderRadius);
  border-color: '#fffff1';
  font-size: 1rem;
  color: black;
}

.signin-google:hover {
  border-color: var(--tertiaryColor);
  cursor: pointer;
}

.signin-google > img {
  width: 30px;
}

.login-button {
  height: 45px;
  width: 100%;
  margin: 1rem 0;
  background-color: var(--tertiaryColor);
  border-style: none;
  border-radius: var(--globalBorderRadius);
  font-size: 1rem;
  color: white;
}

.login-button:hover {
  background-color: var(--primaryColor);
  cursor: pointer;
}

.login-button:disabled {
  background-color: lightgray;
  cursor: default;
}
