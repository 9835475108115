:root {
  --primaryColor: #0bcd8b;
  --secondaryColor: #184641;
  --tertiaryColor: #13a77d;
  --premium-bg: #18836b;
  --backgroundColor: #f2f2f2;
  --lv2BgColor: #fff;
  --lv3BgColor: #fff;
  --seperatorColor: #e0e4e9;
  --linkColor: #0f69ff;
  --linkActiveColor: #125bd4;
  --hoverBgColor: #17f3a9;
  --c-fuji-hulk-pants: #7e1fff;
  --c-fuji-dolphin: #6e7780;
  --boxShadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  --xs: 11px;
  --s: 13px;
  --m: 15px;
  --footer-height: 300px;
  --score8Plus: #005eff;
  --score7Plus: #24bbe0;
  --score6Plus: #28e08a;
  --score5Plus: #d8cb55;
  --scoreMinus5: #d37e5d;
  --globalMaxWidth: 1220px;
  --globalBorderRadius: 8px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primaryColor: #0bcd8b;
    --secondaryColor: #184641;
    --tertiaryColor: #13a77d;
    --premium-bg: #18836b;
    --backgroundColor: #f2f2f2;
    --lv2BgColor: #fff;
    --lv3BgColor: #fff;
    --seperatorColor: #e0e4e9;
    --linkColor: #0f69ff;
    --linkActiveColor: #125bd4;
    --hoverBgColor: #17f3a9;
    --c-fuji-hulk-pants: #7e1fff;
    --c-fuji-dolphin: #6e7780;
    --boxShadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    --xs: 11px;
    --s: 13px;
    --m: 15px;
    --footer-height: 300px;
    --score8Plus: #005eff;
    --score7Plus: #24bbe0;
    --score6Plus: #28e08a;
    --score5Plus: #d8cb55;
    --scoreMinus5: #d37e5d;
    --globalMaxWidth: 1220px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--backgroundColor) !important;
  line-height: 1.6;
  font-size: 16px;
}

header {
  display: flex;
  justify-content: center;
  background-color: var(--premium-bg);
  padding: 0.5rem;
}

header .content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: var(--globalMaxWidth);
}

@media (min-width: 700px) {
  header {
    flex-direction: column;
  }
}

@media (min-width: 1024px) {
  header {
    flex-direction: row;
  }
}

#logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-logo {
  height: 2rem;
}

.App {
  margin: 0 auto;
  text-align: center;
}

.main-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--footer-height));
}

.chart {
  display: flex;
  justify-content: center;
  min-height: 250px;
  /* border-radius: 8px; */
  background-color: white;
  /* padding: 1rem; */
}

.chart.interactive:hover {
  cursor: pointer;
}

.chart.locked {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 300px) {
  .header-logo {
    height: 2.3rem;
  }

  .chart {
    min-height: 200px;
    padding: 0rem;
  }
}

@media (min-width: 700px) {
  .header-logo {
    height: 2.3rem;
  }

  .chart {
    min-height: 250px;
    /* padding: 1rem; */
  }
}

@media (min-width: 1024px) {
  .header-logo {
    height: 2.3rem;
  }

  .App {
    margin: 0 auto;
    width: 100%;
    max-width: '1440px';
    text-align: center;
  }

  .chart {
    min-height: 250px;
    /* padding: 1rem; */
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.menu {
  text-align: center;
  font-weight: 500;
}

.menu.dark {
  background-color: white;
}

.menu > ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  list-style-position: outside;
  margin: 0;
  padding: 0;
}

.menu > ul > li > a {
  color: white;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
}

.menu.dark > ul > li > a {
  color: black;
}

.menu > ul > li > a:hover {
  color: var(--primaryColor);
  /* text-decoration: underline; */
}

.menu > ul > li {
  display: inherit;
  padding: 0 0.5rem;
  margin: 0;
}

@media (min-width: 300px) {
  header .content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .menu > ul {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 700px) {
  header .content {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }

  .menu > ul {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  header .content {
    display: flex;
    flex-direction: row;
    max-width: var(--globalMaxWidth);
  }

  .menu > ul {
    display: flex;
    flex-direction: row;
  }
}

.simple-metrics-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.big-number {
  min-width: 100px;
  margin: 0.5rem;
  padding: 1rem;
  border-radius: var(--globalBorderRadius);
  background-color: white;
}

.big-number.disabled {
  background-color: lightgray;
  color: grey;
}

.big-number > label {
  font-size: 1.3rem;
  display: block;
  padding-bottom: 0.5rem;
}

.big-number > span {
}

.full-chart {
  /* width: 800px; */
}

.vs-form {
  text-align: center;
  padding-left: 1rem;
}

.vs-form > form > input {
  outline: none;
  /* text-transform: uppercase; */
  text-align: left;
  font-size: 1.3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-decoration: none;
  border: 2px solid white;
  border-radius: var(--globalBorderRadius) 0 0 var(--globalBorderRadius);
  height: 3rem;
  width: 250px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: white;
  box-shadow: var(--boxShadow);
  padding-left: 1rem;
}

.vs-form > form > input:focus {
  border: 2px solid var(--tertiaryColor);
}

.vs-form > form > input::placeholder {
  color: var(--c-fuji-dolphin);
}

.vs-form > form > select {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-decoration: none;
  border: 2px solid white;
  height: calc(3rem + 4px);
  width: 100px;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
  box-shadow: var(--boxShadow);
}

.vs-form > form > select:focus {
  border: 2px solid var(--tertiaryColor);
}

.vs-form > form > select:hover {
  cursor: pointer;
}

.vs-form > form > button[type='submit'] {
  text-align: center;
  font-size: 1.3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-decoration: none;
  border: 2px solid var(--tertiaryColor);
  height: calc(3rem + 4px);
  border-radius: 0 var(--globalBorderRadius) var(--globalBorderRadius) 0;
  width: 50px;
  background-color: var(--tertiaryColor);
  color: white;
  box-shadow: var(--boxShadow);
}

.search-result {
  z-index: 99;
  background-color: white;
  position: absolute;
  width: 500px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem;
  border-radius: var(--globalBorderRadius);
  box-shadow: var(--boxShadow);
}

@media (min-width: 300px) {
  .search-result {
    width: 100%;
    text-align: center;
  }

  .vs-form {
    padding-left: 0;
  }

  .vs-form > form {
    display: inline-flex;
  }

  .vs-form > form > input {
    width: 250px;
  }
}

@media (min-width: 700px) {
  .search-result {
    width: 100%;
    text-align: center;
  }

  .vs-form {
    padding-left: 1rem;
  }

  .vs-form > form {
    display: inline-flex;
  }

  .vs-form > form > input {
    width: 100px;
  }
}

@media (min-width: 1024px) {
  .search-result {
    width: 500px;
    left: initial;
    right: initial;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .vs-form {
    padding-left: 1rem;
  }

  .vs-form > form > input {
    width: 250px;
  }
}

.search-result li {
  cursor: pointer;
  list-style: none;
  list-style-position: outside;
  padding: 0.5rem;
}

.search-result li:hover {
  background-color: var(--primaryColor);
}

.search-result a {
  text-decoration: none;
  color: black;
}

.company-details {
}

.company-details .company-details-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.company-details .company-details-description {
  text-align: justify;
}

.bookmark {
  text-align: center;
  border: none;
  padding-right: 1rem;
  color: black;
}

.bookmark:hover {
  cursor: pointer;
}

.loading-section {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.main-title {
  margin: 0;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-title > img {
  margin-bottom: 1rem;
  width: 96px;
}

.slogan {
  font-size: 1.5rem;
  color: var(--c-fuji-dolphin);
}

footer {
  padding: 1rem;
  color: white;
  background-color: var(--secondaryColor);
  height: var(--footer-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer ul {
  list-style: none;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.footer-content {
  max-width: var(--globalMaxWidth);
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disclaimer {
  text-align: justify;
  font-size: 0.8rem;
  color: whitesmoke;
  max-width: var(--globalMaxWidth);
}

@media (min-width: 300px) {
  footer {
    height: 100%;
  }

  .disclaimer {
    max-width: 100%;
  }
}

@media (min-width: 700px) {
  footer {
    height: 100%;
  }

  .disclaimer {
    max-width: 100%;
  }
}

@media (min-width: 1000px) {
  footer {
    height: var(--footer-height);
  }

  .disclaimer {
    max-width: var(--globalMaxWidth);
  }
}

.main-page {
  font-size: 1.2rem;
  line-height: 1.5;
  max-width: 500px;
  text-align: center;
  margin: 2rem;
  color: var(--tertiaryColor);
}

.stock-preview-chart {
  width: 150px;
  padding: 0.5rem;
  margin: 1rem 0.5rem;
  background-color: white;
  border-radius: var(--globalBorderRadius);
  box-shadow: var(--boxShadow);
}

.stock-preview-chart:hover {
  box-shadow: none;
  cursor: pointer;
}

@media (min-width: 300px) {
  .stock-preview-chart {
    width: 150px;
  }
}

@media (min-width: 700px) {
  .stock-preview-chart {
    width: 150px;
  }
}

@media (min-width: 1024px) {
  .stock-preview-chart {
    width: 200px;
  }
}

.stock-preview-section {
  display: grid;
  /* gap: 1rem; */
  grid-template-columns: 50% 50%;
  /* justify-content: center;
  /* flex-wrap: wrap; */
}

@media (min-width: 300px) {
  .stock-preview-section {
    grid-template-columns: 50% 50%;
  }
}

@media (min-width: 700px) {
  .stock-preview-section {
    grid-template-columns: 50% 50%;
  }
}

@media (min-width: 1024px) {
  .stock-preview-section {
    grid-template-columns: 50% 50%;
  }
}

.bookmarks-link {
  margin: 1rem;
}

.bookmarks-link > a {
  text-decoration: none;
  color: var(--linkColor);
}

.bookmarks-link > a:hover {
  text-decoration: underline;
  color: var(--linkActiveColor);
}

.bookmarks-list {
  margin: 0 auto;
  width: 800px;
  min-height: calc(100vh - var(--footer-height));
}

.bookmarks-list > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: 2.5;
}

.bookmarks-list > ul > li > a {
  text-decoration: none;
  color: var(--linkColor);
}

.bookmarks-list > ul > li:hover > a {
  text-decoration: underline;
  color: var(--linkActiveColor);
}

.back-to-top {
  /* position: relative; */
  /* display: flex; */
  /* justify-content: flex-end; */
  /* align-items: center; */
  position: sticky;
  bottom: 30px;
  --offset: 100px;
  margin-top: calc(100% + var(--offset));
  margin-bottom: var(--offset);
}

.period-button {
  text-transform: uppercase;
  font-size: 1rem;
  height: 3rem;
  cursor: pointer;
  text-decoration: none;
  border: none;
  box-shadow: var(--boxShadow);
  color: white;
  background-color: var(--tertiaryColor);
  border-radius: var(--globalBorderRadius);
  padding: 1rem;
  margin: 1rem;
}

.period-button:hover {
  background-color: var(--primaryColor);
}

.back-to-top button {
  text-transform: uppercase;
  font-size: 1rem;
  height: 3rem;
  cursor: pointer;
  text-decoration: none;
  border: none;
  box-shadow: var(--boxShadow);
  color: white;
  background-color: var(--secondaryColor);
  border-radius: var(--globalBorderRadius);
  padding: 0.5rem 1rem;
}

.main-btn {
  text-transform: uppercase;
  font-size: 1rem;
  height: 3rem;
  cursor: pointer;
  text-decoration: none;
  border: none;
  box-shadow: var(--boxShadow);
  color: white;
  background-color: var(--secondaryColor);
  border-radius: var(--globalBorderRadius);
  padding: 0.5rem 1rem;
}

.main-btn:hover {
  background-color: var(--primaryColor);
}

.back-to-top button:hover {
  background-color: var(--primaryColor);
}

.stack-charts {
  display: grid;
  /* gap: 1rem; */
  grid-template-columns: 50% 50%;
}

@media (min-width: 300px) {
  .stack-charts {
    display: inherit;
    /* flex-direction: column; */
    margin: 1rem 0;
  }
}

@media (min-width: 700px) {
  .stack-charts {
    display: inherit;
    /* flex-direction: column; */
    margin: 1rem 0;
  }
}

@media (min-width: 1024px) {
  .stack-charts {
    display: grid;
    /* gap: 1rem; */
    grid-template-columns: 50% 50%;
  }
}

.period-change-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.not-found {
  display: flex;
  height: calc(100vh - var(--footer-height));
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  height: 42px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  box-shadow: var(--boxShadow);
  color: white;
  background-color: var(--tertiaryColor);
  border-radius: var(--globalBorderRadius);
  padding: 0.1rem 1rem;
}

.primary-button.big {
  font-size: 1.5rem;
  padding: 0.5rem 2rem;
  width: 350px;
}

.primary-button.light {
  color: white;
  background-color: var(--secondaryColor);
}

.primary-button.white {
  color: var(--secondaryColor);
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.primary-button.white:hover {
  color: white;
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

.primary-button.danger {
  color: white;
  background-color: var(--scoreMinus5);
}

.primary-button.danger:hover {
  background-color: darkred;
}

.primary-button:hover {
  background-color: var(--primaryColor);
}

.primary-button.light:hover {
  background-color: var(--tertiaryColor);
}

section {
  /* padding: 2rem; */
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
}

.get-premium {
  width: 150px;
  margin: 2rem auto;
}

.get-premium-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  padding: 3rem;
  /* background-color: whitesmoke; */
}

@media (min-width: 300px) {
  .get-premium-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    padding: 0;
    /* background-color: whitesmoke; */
  }
}

@media (min-width: 700px) {
  .get-premium-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    padding: 3rem;
    /* background-color: whitesmoke; */
  }
}

@media (min-width: 1000px) {
  .get-premium-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    padding: 3rem;
    /* background-color: whitesmoke; */
  }
}

@media (min-width: 300px) {
  section {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .get-premium {
    margin: 2rem auto;
  }
}

@media (min-width: 700px) {
  section {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .get-premium {
    margin: 2rem auto;
  }
}

@media (min-width: 1024px) {
  section {
    flex-direction: row;
    text-align: left;
    width: 100%;
  }

  .get-premium {
    margin: 2rem 0;
  }
}

section > div {
  padding: 2rem;
  flex-basis: 50%;
}

section .section-picture > img {
  width: 100%;
  border-radius: var(--globalBorderRadius);
  box-shadow: var(--boxShadow);
  background-color: white;
}

section h1 {
  font-size: 2.2rem;
  color: var(--premium-bg);
}

section h2 {
  font-size: 2.2rem;
  color: var(--premium-bg);
}

section p {
  font-size: 1.8rem;
  color: var(--secondaryColor);
}

.premium-preview {
  background-color: var(--premium-bg);
}

.premium-preview h1 {
  color: white;
  font-size: 3rem;
  margin-top: 10rem;
}

.premium-preview h2 {
  color: white;
}

.premium-preview p {
  color: white;
}

.premium-preview img {
  box-shadow: var(--boxShadow);
}

.error-message {
  text-align: center;
  color: darkred;
  background-color: whitesmoke;
}

.checkmark__circle {
  stroke-dasharray: 216;
  stroke-dashoffset: 216;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--primaryColor);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px var(--primaryColor);
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 98;
  stroke-dashoffset: 98;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 80px var(--primaryColor);
  }
}

.dark-section {
  background-color: var(--secondaryColor);
}

.dark-section p {
  color: white;
}

.dark-section h1 {
  color: white;
}

.dark-section h2 {
  color: white;
}

.section-description p {
  font-size: 1.5rem;
}

.section-description ul {
  padding: 0 0.3rem;
  margin: 0 auto;
}

.section-description li {
  text-align: left;
  font-size: 1.5rem;
  color: white;
  list-style: none;
  padding-left: 1rem;
  text-indent: -1.5rem;
}

.section-description li::before {
  content: '✔️ ';
}

.vote-btn {
  padding: 0.5rem;
  border-radius: var(--globalBorderRadius);
}

.vote-btn:hover {
  background-color: var(--primaryColor);
  cursor: pointer;
}

.metrics-table {
  display: grid;
  margin: 1rem auto;
  padding: 0.5rem;
  /* border-radius: 12px; */
  /* width: 500px; */
  grid-template-columns: 25% 25% 25% 25%;
  text-align: left;
  background-color: white;
}

@media (min-width: 300px) {
  .section-description {
    width: 100%;
  }

  .section-picture {
    width: 100%;
  }

  .section-description .primary-button.big {
    width: 200px;
    margin: 0 auto;
    /* padding: 0; */
  }

  .metrics-table {
    grid-template-columns: 50% 50%;
  }
}

@media (min-width: 700px) {
  .section-description {
    width: 50%;
  }

  .section-picture {
    width: 50%;
  }

  .section-description .primary-button.big {
    width: 350px;
    margin: 0 auto;
    /* padding: 0; */
  }

  .metrics-table {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

@media (min-width: 1000px) {
  .section-description {
    width: 50%;
  }

  .section-picture {
    width: 50%;
  }

  .section-description .primary-button.big {
    width: 450px;
    margin: 0;
    /* padding: 0; */
  }

  .metrics-table {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

.metrics-table > div:nth-child(2n - 1) {
  font-weight: 500;
  color: var(--secondaryColor);
}

/* .metrics-table > div:nth-child(4n + 3) {
  background-color: whitesmoke;
}

.metrics-table > div:nth-child(4n + 4) {
  background-color: whitesmoke;
} */

.metrics-table > div {
  padding: 0.5rem;
}

.mighty-score {
  /* font-weight: 400; */
  color: black;
  padding-right: 0.35rem;
}

@media (min-width: 300px) {
  .app-wrapper {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}
@media (min-width: 700px) {
  .app-wrapper {
    max-width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 1000px) {
  .app-wrapper {
    margin: 0 auto;
    max-width: var(--globalMaxWidth);
  }
}

.score-e {
  background-color: var(--scoreMinus5);
}

.score-d {
  background-color: var(--score5Plus);
}

.score-c {
  background-color: var(--score6Plus);
}

.score-b {
  background-color: var(--score7Plus);
}

.score-a {
  background-color: var(--score8Plus);
}

.paper-div {
  margin-top: 2rem;
}

.paper-div > #description {
  width: 100%;
}

tr {
  height: 3rem !important;
}

tbody > tr:hover {
  box-shadow: var(--boxShadow);
}

.explain-modal {
  width: var(--globalMaxWidth);
}

@media (min-width: 300px) {
  .explain-modal {
    width: 100%;
  }
}
@media (min-width: 700px) {
  .explain-modal {
    width: 100%;
  }
}
@media (min-width: 1000px) {
  .explain-modal {
    width: var(--globalMaxWidth);
  }
}
