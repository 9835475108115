.privacy-section {
  width: 600px;
  margin: 4rem auto;
}

@media (min-width: 300px) {
  .privacy-section {
    width: 300px;
  }
}

@media (min-width: 700px) {
  .privacy-section {
    width: 600px;
  }
}

.privacy-section a {
  color: var(--tertiaryColor);
  text-decoration: none;
}

.privacy-section a:hover {
  color: var(--primaryColor);
  text-decoration: underline;
}

.privacy-section h2 {
  font-size: 2rem;
}

.privacy-section h3 {
  font-size: 1.5rem;
}
