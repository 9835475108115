.features {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

/* .payment-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.features-wrapper ul > li {
  padding-bottom: 1rem;
}

.money-back {
  text-align: center;
  color: var(--prmaryColor);
}

.screener-content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.screener-content > h1 {
  margin-bottom: 0;
}

.screener-content > p {
  margin-bottom: 1rem;
  text-align: center;
}

.screener-grid-container {
  display: inline-block;
  width: 50vw;
  grid-template-columns: 25% 25% 25% 25%;
}

.screener-description {
  color: var(--c-fuji-dolphin);
  background-color: white;
  border-radius: var(--globalBorderRadius);
  padding: 0.5rem;
  box-sizing: border-box;
}

@media (min-width: 300px) {
  .screener-grid-container {
    width: 350px;
    grid-template-columns: 50% 50%;
  }
  .screener-description {
    width: 350px;
  }
}

@media (min-width: 700px) {
  .screener-grid-container {
    width: 700px;
    grid-template-columns: 25% 25% 25% 25%;
  }
  .screener-description {
    width: 700px;
  }
}

@media (min-width: 1024px) {
  .screener-grid-container {
    width: var(--globalMaxWidth);
    grid-template-columns: 25% 25% 25% 25%;
  }
  .screener-description {
    width: var(--globalMaxWidth);
  }
}

.score-badge {
  color: white;
  padding: 0 0.35rem;
}

.score-badge:hover {
  cursor: pointer;
}

.score-badge > b {
  padding: 0.2rem 0.5rem;
  border-radius: 15px;
  box-shadow: var(--boxShadow);
}

.score-badge .plus8 {
  background-color: var(--score8Plus);
}

.score-badge .plus7 {
  background-color: var(--score7Plus);
}

.score-badge .plus6 {
  background-color: var(--score6Plus);
}

.score-badge .plus5 {
  background-color: var(--score5Plus);
}

.score-badge .minus5 {
  background-color: var(--scoreMinus5);
}

.stock-item {
  width: var(--globalMaxWidth);
  height: 3rem;
  color: black;
}

.stock-item:hover {
  background-color: var(--boxShadow);
  cursor: pointer;
}

.screener-pages {
  padding: 2rem;
  color: black;
}

.screener-pages > span {
  padding: 0.5em;
  /* font-size: 1.3rem; */
  font-weight: 500;
}

.screener-pages > span.selected-page {
  font-weight: 600;
  color: var(--tertiaryColor);
  text-decoration: underline;
}

.screener-pages > span:hover {
  text-decoration: underline;
  cursor: pointer;
  color: var(--primaryColor);
}

.screener-empty {
  margin: auto;
}

.accept-section {
  margin: 1rem 0;
  display: inline-block;
  text-align: center;
}

.accept-section p {
  font-size: 0.8rem;
}

.accept-section input[type='checkbox'] {
  transform: scale(1.4);
  margin: 0 1rem 0 0;
}

.screener-search-box {
  width: 200px;
}

.buy {
  width: 100%;
}

.back-to-top-wrapper {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
